export const DATA_TUTORIAL_SIMULATED = [
  {
    id: 0,
    title: 'Olá, tudo bem com você?',
    text: '<p>Antes de começar, gostaríamos de explicar como funciona os nossos simulados.</p>',
  },
  {
    id: 1,
    title: 'Sobre os simulados',
    text: '<p>Nossos simulados é uma ferramenta online que replica as condições do vestibular escolhido, incluindo <strong>a duração da prova</strong> e a <strong>metodologia de correção T.R.I.</strong></p>',
  },
  {
    id: 2,
    title: 'Tipos de simulados',
    text: '<p>Hoje possuímos dois tipos de simulados na plataforma: <strong>vestibulares anteriores</strong>, o qual são as provas anteriores do ENEM e <strong>simulados do Explicaê</strong>, o qual são nossas provas autorais.</p>',
    imgs: [
      {
        src: 'list-simulations.png',
        alt: 'A imagem mostra a listagem de simulados',
      },
    ],
  },
  {
    id: 3,
    title: 'Instruções',
    text: '<p>Além dos conteúdos, você também tem acesso a outras funcionalidades como:</p><br><ul><li><strong>Só poderá fazer uma única vez;</strong></li><li><strong>A quantidade de questões e a duração da prova são as mesmas que o do vestibular escolhido;</strong></li><li><strong>E diferente das redações avulsas na plataforma, a redação só poderá ser enviada uma vez.</strong></li></ul>',
    imgs: [
      {
        src: 'instruction-simulated.png',
        alt: 'A imagem mostra a página de instrução do simulado.',
      },
    ],
  },
  {
    id: 4,
    title: 'Resultados',
    text: '<p>Após a realização do simulado, você receberá um <strong>relatório detalhado do desempenho na prova</strong>, com informações de pontuação e desempenho em cada área avaliada.</p>',
    imgs: [
      {
        src: 'result-simulated.png',
        alt: 'A imagem mostra a página do resultado do simulado.',
      },
    ],
  },
  {
    id: 5,
    title: 'Simulador SISU',
    text: '<p>Você poderá fazer uma simulação SISU de <strong>possíveis resultados</strong> e dos <strong>resultados obtidos nos nossos simulados.</strong> Ao fazer essa simulação você poderá ter uma ideia de suas chances de aprovação nas instituições e cursos desejados.</p>',
    imgs: [
      {
        src: 'simulator-sisu.png',
        alt: 'A imagem mostra a página do simulador do sisu.',
      },
    ],
  },
  {
    id: 6,
    title: 'Conheceu nossos Simulados?',
    text: '<p>Agora que já sabe como que funciona nossos simulados. Que tal praticar um pouco e fazer um simulado?</p>',
  },
];
